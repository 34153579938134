import React, { useEffect } from 'react';
import { object, string } from 'prop-types';
import lozad from 'lozad';

import styles from './Calendly.module.scss';
import { RichText } from 'prismic-reactjs';

const Calendly = ({ primary }) => {
  const { title, link } = primary;

  useEffect(() => {
    const observer = lozad();
    observer.observe();
  }, []);

  return (
    <section className={styles.calendly}>
      <div className={styles.title}>
        <RichText render={title.richText} />
      </div>
      <div
        className={styles.calendar}
        role="region"
        aria-label="Calendly Booking Calendar"
      >
        <iframe
          src={link?.url}
          sp-consent="Calendly"
          title="Calendly"
          width="100%"
          height="100%"
          frameBorder="0"
          className="lozad"
        ></iframe>
      </div>
    </section>
  );
};

Calendly.propTypes = {
  primary: object,
  calendlyLink: string,
};

export default Calendly;
